import AdminMessages from "../components/AdminMessages";
import "../components/styleResources/text-gradient.css";
import { useUsername } from "../context/UsernameContext";

function CreatorDashboard() {
  // Access username, userType and error from context
  const { username, userType } = useUsername();

  return (
    <>
      <h1 className="text-2xl font-semibold text-center mt-4 tracking-tight">
        Hello
        <span className="text-2xl font-semibold ml-2 text-gradient">
          {username}
        </span>
      </h1>
      <div className="my-10 ml-5">
        {userType === "Creator" && <AdminMessages />}
      </div>
    </>
  );
}

export default CreatorDashboard;
