import React from "react";
import brandText from "../brandText.png";
import { Link } from "react-router-dom";

function BottomNav() {
  return (
    <footer className="bg-custom-customblack text-white py-4">
      <div className="flex justify-center">
        <img className="w-36 mx-auto my-auto" src={brandText} alt="" />
        <ul className="mx-auto my-auto">
          <li className="mb-2">
            <Link className="linkB" to="/">
              Home
            </Link>
          </li>
          <li className="mb-2">
            <Link className="linkB" to="/about">
              About
            </Link>
          </li>
          <li className="mb-2">
            <Link className="linkB" to="/courses">
              Courses
            </Link>
          </li>
        </ul>
        <ul className="mx-auto my-auto">
          <li className="mb-2">
            <Link className="linkB" to="/learn-with-sharex">
              Learn on ShareX
            </Link>
          </li>
          <li className="mb-2">
            <Link className="linkB" to="/teach-with-sharex">
              Teach on ShareX
            </Link>
          </li>
          <li className="mb-2">
            <Link className="linkB" to="/create-with-sharex">
              Create with Share
            </Link>
          </li>
        </ul>
        <p className="text-center mx-auto my-auto">&copy; 2024 TheShareX</p>
      </div>
    </footer>
  );
}

export default BottomNav;
