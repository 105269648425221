import "rsuite/dist/rsuite.min.css";
import Dropdown from "rsuite/Dropdown";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import ExitIcon from "@rsuite/icons/Exit";
import GearIcon from "@rsuite/icons/Gear";
import UserBadgeIcon from "@rsuite/icons/UserBadge";
import { useUsername } from "../context/UsernameContext";

function DropDownMenu() {
  // Access username and error from context
  const { username, error } = useUsername();

  // Function facilitating signout
  const SignUserOut = () => {
    signOut(auth);
    window.location.reload(false);
  };

  return (
    <div className="mr-5">
      {/* RSuite Dropdown component */}
      <Dropdown title={username} icon={<UserBadgeIcon />}>
        {/* Dropdown items with icons */}
        <Dropdown.Item icon={<GearIcon />} as="a" href="/settings">
          Settings
        </Dropdown.Item>
        <Dropdown.Item icon={<ExitIcon />} as="button" onClick={SignUserOut}>
          Logout
        </Dropdown.Item>
      </Dropdown>
      {/* Render error message if error state is not null */}
      {error && <p>Error: {error}</p>}{" "}
    </div>
  );
}

export default DropDownMenu;
