import "../components/styleResources/text-gradient.css";

function ErrorPage() {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <h1 className="text-6xl text-gradient font-bold text-gray-800">404</h1>
      <span className="text-xl font-bold">Not Found</span>
      <p className="text-gray-600">
        Sorry, the page you are looking for does not exist.
      </p>
      <button className="mt-8 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
        Go Home
      </button>
    </div>
  );
}

export default ErrorPage;
