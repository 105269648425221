import { db, auth } from "../firebase";
import { useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import ContactForm from "./ContactForm";

// This widget enables the users to see the mesages sent to them by the administrator
function AdminMessages() {
  const [adminMessageInfo, setAdminMessageInfo] = useState([]);
  const [adminMessageCount, setAdminMessageCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [newMessageModalOpen, setNewMessageModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    senderName: "",
    senderEmail: "",
    messageBody: "",
    messageId: "",
    messageRead: false,
  });

  // UseEffect to retrieve all messages sent to the user
  useEffect(() => {
    const getAdminMessageInfo = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, auth.currentUser.email)
        );
        const messages = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setAdminMessageInfo(messages);
        setAdminMessageCount(messages.length);
      } catch (error) {
        alert("Error fetching admin messages:", error);
      }
    };

    getAdminMessageInfo();
  }, []);

  // Function to trigger a modal upon clicking the email of individual messages
  const handleEmailClick = (user) => {
    setModalContent(user);
    setShowModal(true);
  };

  // Function to handle message read/unread
  const handleMessageRead = async () => {
    try {
      const { messageId } = modalContent;
      if (messageId) {
        await updateDoc(doc(db, auth.currentUser.email, messageId), {
          messageRead: !modalContent.messageRead,
        });
        setModalContent((prevContent) => ({
          ...prevContent,
          messageRead: !prevContent.messageRead,
        }));
        setAdminMessageInfo((prevMessages) =>
          prevMessages.map((message) =>
            message.id === messageId
              ? { ...message, messageRead: !modalContent.messageRead }
              : message
          )
        );
      }
    } catch (error) {
      alert("Error updating message read status:", error);
    }
  };

  return (
    <>
      <div className="max-w-2xl bg-white rounded-lg overflow-hidden shadow-md">
        {/* <div> */}
        <div className="bg-gray-200 p-2 flex justify-between items-center">
          <h5 className="mr-auto ml-2">Messages</h5>
          <button
            className="bg-blue-500 text-white text-sm font-medium px-3 py-1 rounded-md hover:bg-blue-600 focus:outline-none"
            onClick={() => setNewMessageModalOpen(true)}
          >
            + New Message
          </button>
        </div>

        {newMessageModalOpen ? (
          <ContactForm
            newMessageModalOpen={newMessageModalOpen}
            setNewMessageModalOpen={setNewMessageModalOpen}
          />
        ) : null}

        <div className="overflow-auto" style={{ maxHeight: "250px" }}>
          {adminMessageInfo.map((admin_messages) => (
            <div
              key={admin_messages.id}
              className="flex items-center justify-center border-b my-2 border-gray-300"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-8 w-8 ml-2 rounded-full"
                  src="https://via.placeholder.com/40"
                  alt=""
                />
              </div>
              <div className="ml-3 flex-1">
                <div className="flex items-center justify-between">
                  <div>
                    <button
                      className="text-md text-gray-900 font-bold"
                      onClick={() =>
                        handleEmailClick({
                          senderName: admin_messages.senderName,
                          senderEmail: admin_messages.senderEmail,
                          messageBody: admin_messages.messageBody,
                          messageId: admin_messages.id,
                          messageRead: admin_messages.messageRead,
                        })
                      }
                    >
                      {admin_messages.senderEmail}
                    </button>
                  </div>
                  <p className="text-xs text-gray-500 mr-1">
                    {formatDistanceToNow(admin_messages.messageTime.toDate(), {
                      addSuffix: true,
                    })}
                    {admin_messages.messageRead ? (
                      <span className="bg-blue-500 text-white px-1 rounded-md mx-1">
                        R
                      </span>
                    ) : (
                      <span className="bg-green-500 text-white px-1 rounded-md mx-1">
                        U
                      </span>
                    )}
                  </p>
                </div>
                <p className="text-xs text-gray-500 mt-1 mb-2">
                  {admin_messages.messageBody?.substring(0, 40) + "..."}
                </p>
              </div>
            </div>
          ))}
        </div>
        <p className="text-sm text-gray-500 mb-1 ml-2">
          Total Messages:{" "}
          <span className="font-bold text-gray-700">{adminMessageCount}</span>
        </p>
      </div>
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="relative flex flex-col items-center bg-white p-8 rounded-lg">
            <button
              className="absolute px-3 py-1 rounded-md top-2 right-2 bg-red-500 text-gray-100 hover:bg-red-600"
              onClick={() => setShowModal(false)}
            >
              x
            </button>
            <img
              className="h-32 w-32 rounded-full mb-4"
              src="https://via.placeholder.com/150"
              alt=""
            />
            <h3 className="text-2xl font-bold">{modalContent.senderName}</h3>
            <span className="text-sm text-gray-600 mb-1">
              {modalContent.senderEmail}
            </span>
            <p className="max-w-72 text-center text-sm text-gray-800">
              {modalContent.messageBody}
            </p>
            <div className="flex">
              <button
                className={`bg-blue-500 text-white rounded-md px-4 py-2 mt-4 ${
                  modalContent.messageRead ? "bg-blue-500" : "bg-green-500"
                }`} // Change button color based on messageRead value
                onClick={handleMessageRead}
              >
                {modalContent.messageRead ? "Unread" : "Read"}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* </div> */}
    </>
  );
}

export default AdminMessages;
