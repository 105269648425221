import { useState } from "react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";

function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  let navigate = useNavigate();

  // Handle form submission: Authenticate user and navigate to "/" on success
  const handleUserLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (error) {
      alert("Error: ", error.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-200 flex justify-center items-center">
      <form
        onSubmit={handleUserLogin}
        className="bg-white p-8 rounded shadow-md flex flex-col items-center space-y-4"
      >
        <h2 className="text-2xl font-semibold text-center font-vround">
          Login
        </h2>
        <div className="w-full">
          <label htmlFor="email" className="block mb-1 font-jsans">
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full rounded border border-gray-300 shadow-sm focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div className="w-full">
          <label htmlFor="password" className="block mb-1 font-jsans">
            Password
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full rounded border border-gray-300 shadow-sm focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-indigo-500 text-white py-2 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;
