import { db, auth } from "../firebase";
import { createContext, useContext, useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";

// Create a context
const UsernameContext = createContext();

// Create a provider component
export const UsernameProvider = ({ children }) => {
  const [username, setUsername] = useState("");
  const [userType, setuserType] = useState("");
  const [error, setError] = useState(null);

  // useEffect to obtain currentUser's username
  useEffect(() => {
    // Query to fetch user doc based on email
    const q = query(
      collection(db, "users"),
      where("email", "==", auth.currentUser.email)
    );

    // Function to fetch data from firestore
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const rval = doc.data();
          setUsername(rval.username);
          setuserType(rval.accountType);
        });
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();
  }, []);

  return (
    <UsernameContext.Provider value={{ username, userType, error }}>
      {children}
    </UsernameContext.Provider>
  );
};

// Custom hook to use the UsernameContext
export const useUsername = () => {
  return useContext(UsernameContext);
};
