import Home from "./pages/Home";
import { auth } from "./firebase";
import Login from "./pages/Login";
import About from "./pages/About";
import Nav from "./components/Nav";
import LearnX from "./pages/LearnX";
import TeachX from "./pages/TeachX";
import Courses from "./pages/Courses";
import CreateX from "./pages/CreateX";
import Register from "./pages/Register";
import Settings from "./pages/Settings";
import ErrorPage from "./pages/ErrorPage";
import { useState, useEffect } from "react";
import BottomNav from "./components/BottomNav";
import { onAuthStateChanged } from "firebase/auth";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [userState, setUserState] = useState(null);

  // Check for changes in user authentication with Firebase
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserState(user ? true : false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Nav />
          <main className="flex-grow">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/create-with-sharex" element={<CreateX />} />
              <Route path="/learn-with-sharex" element={<LearnX />} />
              <Route path="/teach-with-sharex" element={<TeachX />} />
              <Route
                path="/register"
                element={userState ? <Home /> : <Register />}
              />
              <Route path="/login" element={userState ? <Home /> : <Login />} />
              <Route
                path="/settings"
                element={userState ? <Settings /> : <Register />}
              />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </main>
          <BottomNav />
        </div>
      </Router>
    </>
  );
}

export default App;
