import {
  BsPersonCheckFill,
  BsBook,
  BsSearch,
  BsCreditCard,
  BsPlayFill,
  BsChatDots,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import "../components/styleResources/text-gradient.css";
import stepsData from "../components/textResources/services_description/learning_procedure.json";

// Function to render icon based on icon name
const renderIcon = (iconName) => {
  switch (iconName) {
    case "BsPersonCheckFill":
      return <BsPersonCheckFill />;
    case "BsBook":
      return <BsBook />;
    case "BsSearch":
      return <BsSearch />;
    case "BsCreditCard":
      return <BsCreditCard />;
    case "BsPlayFill":
      return <BsPlayFill />;
    case "BsChatDots":
      return <BsChatDots />;
    default:
      return null;
  }
};

function LearnX() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto py-8">
        <h1 className="text-3xl font-bold mb-8 text-center">
          Learn With{" "}
          <span className="text-gradient font-semibold text-4xl font-spartan tracking-wide">
            Sharex
          </span>
        </h1>

        <div className="mb-10">
          <h2 className="text-lg font-semibold mb-4 text-center">
            Steps to Learn with Sharex:
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-4xl mx-auto">
            {stepsData.steps.map((step, index) => (
              <div
                key={index}
                className="p-6 bg-white rounded-lg shadow-lg flex flex-col items-center"
              >
                <h3 className="text-lg font-semibold mb-2">
                  Step {index + 1}:
                </h3>
                <div className="text-3xl text-blue-500 mb-4">
                  {renderIcon(step.icon)}
                </div>
                <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600 text-center">{step.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-10 text-center">
          <h2 className="text-2xl font-semibold mb-4">
            See all available courses
          </h2>
          <div className="flex justify-center">
            <Link to="/courses">
              <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg shadow-lg">
                Browse Courses
              </button>
            </Link>
          </div>
        </div>

        <div className="text-center">
          <p className="text-lg text-gray-700">
            Don't have an account yet?{" "}
            <a href="/register" className="text-blue-500">
              Sign up here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LearnX;
