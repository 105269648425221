import { auth } from "../firebase";
import { useState, useEffect } from "react";
import "./styleResources/text-gradient.css";
import { useNavigate } from "react-router-dom";
import TotalUsers from "../components/TotalUsers";
import UserMessages from "../components/UserMessages";
import { useUsername } from "../context/UsernameContext";

function AdminDashboard() {
  const { username, error } = useUsername();
  const [superUserState, setSuperUserState] = useState();
  let navigate = useNavigate();

  // Checks for the super user state
  useEffect(() => {
    if (
      auth.currentUser &&
      auth.currentUser.uid === process.env.REACT_APP_SUPER_USER_UID
    ) {
      setSuperUserState(true);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      <h1 className="text-2xl font-medium text-center mt-4 tracking-tight">
        Hello
        <span className="ml-2 text-gradient font-medium">{username}</span>
        <span className="ml-2 text-red-600">(Super User)</span>
      </h1>
      {superUserState && (
        <>
          <div className="my-5 ml-3">
            <TotalUsers />
          </div>
          <div className="mb-5 ml-3">
            <UserMessages />
          </div>
        </>
      )}
      {error && <p>Error: {error}</p>}
    </div>
  );
}

export default AdminDashboard;
