import { Link } from "react-router-dom";
import "../components/styleResources/text-gradient.css";
import aboutTR from "../components/textResources/aboutTR.json";

function About() {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg text-center font-semibold tracking-tight text-gray-600 sm:text-4xl">
          {aboutTR.title + " "}
          <span className="text-gradient font-semibold text-4xl font-spartan tracking-wide">
            Sharex
          </span>
        </h2>
        <p className="mt-6 text-center text-lg text-gray-600">
          {aboutTR.description}
        </p>
        <div className="mt-14">
          <h3 className="text-xl font-semibold text-gray-900">Our Services:</h3>
          <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-6">
            {aboutTR.services.map((service, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <h4 className="text-base font-semibold text-gray-900">
                  {service.title + " "}
                  <span className="text-gradient text-xl font-semibold font-spartan tracking-wide">
                    thesharex
                  </span>
                </h4>
                <p className="mt-2 text-gray-600">{service.description}</p>
                <div className="mt-4 flex justify-center">
                  <Link to={service.link}>
                    <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                      {service.buttonText}
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
