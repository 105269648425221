import { FaTools } from "react-icons/fa";

function Courses() {
  return (
    <div className="translucent-banner mb-10">
      <FaTools className="icon" />
      <span className="text-xs">Courses coming soon!</span>
    </div>
  );
}

export default Courses;
