import { FaTools } from "react-icons/fa";
import "../components/styleResources/text-gradient.css";
import AdminMessages from "../components/AdminMessages";
import { useUsername } from "../context/UsernameContext";

function StudentDashboard() {
  const { username, userType } = useUsername();

  return (
    <>
      <h1 className="text-2xl font-medium text-center mt-4 align">
        Hello
        <span className="text-2xl font-medium ml-2 tracking-tighter text-gradient">
          {username}
        </span>
      </h1>
      <div className="translucent-banner mb-10">
        <FaTools className="icon" />
        <span className="text-xs">
          More improvements and features coming soon!
        </span>
      </div>
      {userType === "Student" && <AdminMessages />}
    </>
  );
}

export default StudentDashboard;
