import { useState } from "react";
import { db, auth } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

function Register() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accountType, setAccountType] = useState(null);
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  // Handle form submission: Send verification email and navigate on success
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!accountType) {
      setError("Please select an account type!");
      return;
    }
    try {
      // Create the user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(userCredential.user);
      // Add user data to Firestore
      await addDoc(collection(db, "users"), {
        email: email,
        username: username,
        accountType: accountType,
      });
      alert(
        "A verification email has been sent to you. Please click on the link in the e-mail to verify"
      );
      navigate("/");
    } catch (err) {
      // Handle errors during registration
      setError(err.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-200 flex justify-center items-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded shadow-md flex flex-col items-center space-y-4"
      >
        <h2 className="text-2xl font-semibold text-center font-vround">
          Register
        </h2>
        <div className="w-full">
          <label htmlFor="username" className="block mb-1 font-jsans">
            Username
          </label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username here"
            className="w-full rounded border border-gray-300 shadow-sm focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div className="w-full">
          <label htmlFor="email" className="block mb-1 font-jsans">
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email here"
            className="w-full rounded border border-gray-300 shadow-sm focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div className="w-full">
          <label htmlFor="password" className="block mb-1 font-jsans">
            Password
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password here"
            className="w-full rounded border border-gray-300 shadow-sm focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        {/* Select Account Type */}
        <p className="font-jsans text-gray-800 mb-1">Select Account Type:</p>
        <div className="flex space-x-4">
          {/* Student Button */}
          <button
            type="button"
            className={`border border-black px-4 py-2 text-black rounded ${
              accountType === "Student"
                ? "bg-gray-300"
                : "bg-transparent hover:bg-gray-200"
            }`}
            onClick={() => setAccountType("Student")}
          >
            Student
          </button>
          {/* Teacher Button */}
          <button
            type="button"
            className={`border border-black px-4 py-2 text-black rounded ${
              accountType === "Teacher"
                ? "bg-gray-300"
                : "bg-transparent hover:bg-gray-200"
            }`}
            onClick={() => setAccountType("Teacher")}
          >
            Teacher
          </button>
          {/* Creator Button */}
          <button
            type="button"
            className={`border border-black px-4 py-2 text-black rounded ${
              accountType === "Creator"
                ? "bg-gray-300"
                : "bg-transparent hover:bg-gray-200"
            }`}
            onClick={() => setAccountType("Creator")}
          >
            Creator
          </button>
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <button
          type="submit"
          className="w-full bg-indigo-500 text-white py-2 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          Register
        </button>
      </form>
    </div>
  );
}

export default Register;
