import { useEffect, useState } from "react";
import { db, auth } from "../firebase";
import {
  collection,
  query,
  where,
  addDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

function ContactForm({ newMessageModalOpen, setNewMessageModalOpen }) {
  const senderEmail = auth.currentUser.email;
  const [senderName, setSenderName] = useState();
  const [messageTitle, setMessageTitle] = useState();
  const [messageBody, setMessageBody] = useState();
  const [recipientEmail, setRecipientEmail] = useState();
  const [userInfo, setUserInfo] = useState();

  // useEffect to fetch user data when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userEmail = currentUser.email;
        const userQuery = query(
          collection(db, "users"),
          where("email", "==", userEmail)
        ); // Query to fetch user data
        const querySnapshot = await getDocs(userQuery);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data(); // Extract user data from query
          setUserInfo(userData);
          // Determine recipient email based on account type
          switch (userData.accountType) {
            case "Teacher":
              setRecipientEmail("");
              break;
            case "Student":
              setRecipientEmail("casper@gmail.com");
              break;
            case "Creator":
              setRecipientEmail(process.env.REACT_APP_SUPER_USER_EMAIL_ID);
              break;
            default:
              setRecipientEmail("");
          }
        }
      }
    };
    fetchUserData();
  }, []);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Adding document to the userMessages
      await addDoc(collection(db, "userMessages"), {
        senderEmail: senderEmail,
        senderName: senderName,
        recipientEmail: recipientEmail,
        messageTitle: messageTitle,
        messageBody: messageBody,
        messageTime: serverTimestamp(),
        messageRead: false,
      });
      alert("Your message has been sent!");
      setSenderName("");
      setMessageTitle("");
      setMessageBody("");
    } catch (err) {
      alert("Error: ", err);
    }
  };

  return (
    <>
      {newMessageModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="relative bg-white p-8 rounded-lg max-w-sm w-full mx-auto shadow-md">
            <button
              onClick={() => setNewMessageModalOpen(false)}
              className="absolute top-2 right-2 px-3 py-1 rounded-md bg-red-500 text-white hover:bg-red-600"
            >
              x
            </button>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Your Name"
                  onChange={(e) => setSenderName(e.target.value)}
                  value={senderName}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="title"
                >
                  Title
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Message Title"
                  onChange={(e) => setMessageTitle(e.target.value)}
                  value={messageTitle}
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="body"
                >
                  Body
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Message"
                  onChange={(e) => setMessageBody(e.target.value)}
                  value={messageBody}
                  required
                />
              </div>
              {userInfo && userInfo.accountType === "Teacher" && (
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="recipient"
                  >
                    Recipient
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    onChange={(e) => {
                      const selectedRecipient = e.target.value;
                      setRecipientEmail(selectedRecipient);
                    }}
                    required
                    value={recipientEmail}
                  >
                    <option value="">Choose Recipient</option>
                    <option value="Admin">Admin</option>
                  </select>
                </div>
              )}
              <div className="flex items-center justify-between">
                <button
                  className="block mx-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
            <p className="text-xs text-gray-500 mt-2">
              *please be clear and concise with the message
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default ContactForm;
