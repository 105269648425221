import { FaTools } from "react-icons/fa";
import "./styleResources/translucent-banner.css";
import "../components/styleResources/text-gradient.css";
import AdminMessages from "../components/AdminMessages";
import { useUsername } from "../context/UsernameContext";

function TeacherDashboard() {
  const { username, userType } = useUsername();

  return (
    <>
      <h1 className="text-2xl font-medium text-center mt-4">
        Hello
        <span className="ml-2 font-medium text-gradient">{username}</span>
      </h1>
      <div className="translucent-banner">
        <FaTools className="icon" />
        <span className="text-xs">
          More improvements and features coming soon!
        </span>
      </div>
      <div className="my-10 ml-5">
        {userType === "Teacher" && <AdminMessages />}
      </div>
    </>
  );
}

export default TeacherDashboard;
