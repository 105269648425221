import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { db, auth } from "../firebase";
import { collection, getDocs, query } from "firebase/firestore";

function TotalUsers() {
  const [superUser, setSuperUserState] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  let navigate = useNavigate();

  // Checks for the super user state
  // Later on, change this by moving the super user state into a separate file
  useEffect(() => {
    if (
      auth.currentUser &&
      auth.currentUser.email === process.env.REACT_APP_SUPER_USER_EMAIL_ID
    ) {
      setSuperUserState(true);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  // Fetch total number of users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userQuery = query(collection(db, "users"));
        const querySnapshot = await getDocs(userQuery);
        const userCount = querySnapshot.size; // Total documents counter
        setTotalDocs(userCount);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    if (superUser) {
      fetchUsers(); // Only fetch data if superUser is true
    }
  }, [superUser]);

  return (
    <div>
      {superUser && (
        <div className="w-32 h-32 bg-gray-200 border border-gray-300 rounded-lg p-4 flex flex-col justify-center items-center">
          <div className="text-sm text-center font-vround text-gray-700 mb-2">
            Number of Users:
          </div>
          <div className="text-2xl text-blue-500 font-bold">{totalDocs}</div>
        </div>
      )}
    </div>
  );
}

export default TotalUsers;
