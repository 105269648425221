import { auth } from "../firebase";
import { Link } from "react-router-dom";
import DropDownMenu from "./DropDownMenu";
import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { UsernameProvider } from "../context/UsernameContext";

function Nav() {
  const [userState, setUserState] = useState(false);

  // Check the user state. If a user is signed in, update the user state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserState(user ? true : false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className="w-full h-12 bg-white sticky top-0 flex justify-center font-jsans">
        <div className="items-center justify-center">
          <Link className="link" to="/">
            <h2 className="text-gradient text-xl font-bold font-spartan ml-4 mt-3">
              thesharex
            </h2>
          </Link>
        </div>

        <div className="flex items-center ml-auto">
          <ul className="flex justify-center list-none m-0 p-0">
            <li className="mr-5 text-lg font-light cursor-pointer">
              <Link className="link" to="/">
                HOME
              </Link>
            </li>

            <li className="mr-5 text-lg font-light cursor-pointer">
              <Link className="link" to="/about">
                ABOUT
              </Link>
            </li>

            <li className="mr-5 text-lg font-light cursor-pointer">
              <Link className="link" to="/courses">
                COURSES
              </Link>
            </li>
          </ul>
        </div>

        <div className="flex items-center justify-right ml-auto">
          {userState ? (
            <UsernameProvider>
              <DropDownMenu />
            </UsernameProvider>
          ) : (
            <ul className="flex justify-center list-none m-0 p-0">
              <li className="mr-5 text-lg font-light cursor-pointer">
                <Link className="link" to="/login">
                  LOGIN
                </Link>
              </li>
              <li className="mr-5 text-lg font-light cursor-pointer">
                <Link className="link" to="/register">
                  REGISTER
                </Link>
              </li>
            </ul>
          )}
          ;
        </div>
      </div>
    </>
  );
}

export default Nav;
