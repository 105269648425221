import { db, auth } from "../firebase";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import ContactForm from "./ContactForm";
import {
  collection,
  getDocs,
  doc,
  addDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

function UserMessages() {
  const [superUserState, setSuperUserState] = useState(false);
  const [userMessageInfo, setUserMessageInfo] = useState([]);
  const [userMessageCount, setUserMessageCount] = useState(0);
  const [senderName, setSenderName] = useState("");
  const [replyText, setReplyText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [replyModal, setReplyModal] = useState(false);
  const [newMessageModalOpen, setNewMessageModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    senderName: "",
    senderEmail: "",
    messageTitle: "",
    messageBody: "",
    messageId: "",
    messageRead: false,
  });
  let navigate = useNavigate();

  // UseEffect to check if the current user is the super user. Move the User UID to a .env file later,
  useEffect(() => {
    const checkSuperUser = async () => {
      const user = auth.currentUser;
      const uid = user ? user.uid : null;
      const superUserUID = process.env.REACT_APP_SUPER_USER_UID;
      if (uid === superUserUID) {
        setSuperUserState(true);
      } else {
        navigate("/login");
      }
    };
    checkSuperUser();

    // Retrieve all the documents in userMessages and map them
    const getUserMessageInfo = async () => {
      const querySnapshot = await getDocs(collection(db, "userMessages"));
      const messages = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setUserMessageInfo(messages);
      setUserMessageCount(messages.length);
    };
    getUserMessageInfo();
  }, [navigate]);

  // UseEffect to update total message count when user message info changes
  useEffect(() => {
    setUserMessageCount(userMessageInfo.length);
  }, [userMessageInfo]);

  // Function to trigger a modal upon clicking the email of individual messages
  const handleEmailClick = (user) => {
    setModalContent(user);
    setShowModal(true);
  };

  // Function to handle message read/unread
  const handleMessageRead = async () => {
    const { messageId } = modalContent;
    if (messageId) {
      await updateDoc(doc(db, "userMessages", messageId), {
        messageRead: !modalContent.messageRead,
      });
      // Update the local state to reflect the change
      setModalContent((prevContent) => ({
        ...prevContent,
        messageRead: !prevContent.messageRead,
      }));

      // Update userMessageInfo with the new messageRead state
      setUserMessageInfo((prevMessages) =>
        prevMessages.map((message) =>
          message.id === messageId
            ? { ...message, messageRead: !modalContent.messageRead }
            : message
        )
      );
    }
  };

  // Function to handle reply button click
  const handleReplyButtonClick = () => {
    setReplyModal(true);
  };

  // Function to handle reply button submission
  const handleReplyButtonSubmit = async (e) => {
    e.preventDefault();
    const recipientEmail = modalContent.senderEmail;
    try {
      await addDoc(collection(db, recipientEmail), {
        senderEmail: process.env.REACT_APP_SUPER_USER_EMAIL_ID,
        senderName: senderName,
        recipientEmail: recipientEmail,
        messageTime: serverTimestamp(),
        messageBody: replyText,
        messageRead: false,
      });
      alert("Message sent!");
      setReplyText("");
    } catch (err) {
      alert("Error: ", err);
    }
  };

  return (
    <>
      <div className="max-w-2xl bg-white rounded-lg overflow-hidden shadow-md">
        {superUserState ? (
          <div>
            <div className="bg-gray-200 p-2 flex justify-between items-center">
              <h5 className="mr-auto ml-2">Messages</h5>
              <button
                className="bg-blue-500 text-white text-sm font-medium px-3 py-1 rounded-md hover:bg-blue-600 focus:outline-none"
                onClick={() => setNewMessageModalOpen(true)}
              >
                + New Message
              </button>
            </div>

            {newMessageModalOpen ? (
              <ContactForm
                newMessageModalOpen={newMessageModalOpen}
                setNewMessageModalOpen={setNewMessageModalOpen}
              />
            ) : null}

            <div className="overflow-auto" style={{ maxHeight: "250px" }}>
              {userMessageInfo.map((user_messages) => (
                <div
                  key={user_messages.id}
                  className="flex items-center justify-center border-b my-2 border-gray-300"
                >
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8 ml-2 rounded-full"
                      src="https://via.placeholder.com/40"
                      alt=""
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <div className="flex items-center justify-between">
                      <div>
                        <button
                          className="text-md text-gray-900 font-bold"
                          onClick={() =>
                            handleEmailClick({
                              senderName: user_messages.senderName,
                              senderEmail: user_messages.senderEmail,
                              messageTitle: user_messages.messageTitle,
                              messageBody: user_messages.messageBody,
                              messageId: user_messages.id,
                              messageRead: user_messages.messageRead,
                            })
                          }
                        >
                          {user_messages.senderEmail}
                        </button>
                      </div>
                      <p className="text-xs text-gray-500 mr-1">
                        {/* Format the string retrieved into proper time format */}
                        {formatDistanceToNow(
                          user_messages.messageTime.toDate(),
                          {
                            addSuffix: true,
                          }
                        )}
                        {/* Toggle the R/U based on messageRead value */}
                        {user_messages.messageRead ? (
                          <span className="bg-blue-500 text-white px-1 rounded-md mx-1">
                            R
                          </span>
                        ) : (
                          <span className="bg-green-500 text-white px-1 rounded-md mx-1">
                            U
                          </span>
                        )}
                      </p>
                    </div>
                    <p className="text-xs text-gray-500 mt-1 mb-2">
                      {/* Limit character length to 40 */}
                      {user_messages.messageBody.substring(0, 40) + "..."}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <p className="text-sm text-gray-500 my-1 ml-2">
              Total Messages:{" "}
              <span className="font-bold text-gray-700">
                {userMessageCount}
              </span>
            </p>
          </div>
        ) : null}
        {showModal && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="relative flex flex-col items-center bg-white p-8 rounded-lg">
              <button
                className="absolute px-3 py-1 rounded-md top-2 right-2 bg-red-500 text-gray-100 hover:bg-red-600"
                onClick={() => setShowModal(false)}
              >
                x
              </button>
              <img
                className="h-32 w-32 rounded-full mb-4"
                src="https://via.placeholder.com/150"
                alt=""
              />
              <h3 className="text-2xl font-bold">{modalContent.senderName}</h3>
              <span className="text-sm text-gray-600 mb-1">
                {modalContent.senderEmail}
              </span>
              <span className="text-base font-vround font-bold text-left">
                {"|" + modalContent.messageTitle}
              </span>
              <p className="max-w-72 text-center text-sm text-gray-800">
                {modalContent.messageBody}
              </p>
              <div className="flex">
                <button
                  className={`bg-blue-500 text-white rounded-md px-4 py-2 mt-4 ${
                    modalContent.messageRead ? "bg-blue-500" : "bg-green-500"
                  }`}
                  onClick={handleMessageRead}
                >
                  {modalContent.messageRead ? "Unread" : "Read"}
                </button>
                <button
                  className="bg-gray-600 text-white rounded-md px-4 py-2 mt-4 ml-5"
                  onClick={handleReplyButtonClick}
                >
                  Reply
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        {replyModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-8 px-14 rounded-lg relative">
              <button
                className="absolute top-2 right-2 px-3 py-1 rounded-md bg-red-500 text-gray-100 hover:bg-red-600"
                onClick={() => setReplyModal(false)}
              >
                x
              </button>
              <span className="text-lg text-gray-900">Replying to:</span>
              <br />
              <p className="mb-4 ml-1 font-xs font-bold">
                <span className="text-blue-500">| </span>
                {modalContent.messageBody.substring(0, 40)}...
              </p>{" "}
              <input
                type="text"
                value={senderName}
                onChange={(e) => setSenderName(e.target.value)}
                placeholder="Sender name"
                className="block w-full border-gray-300 rounded-md mt-2 p-2"
                required
              />
              <textarea
                className="block w-full border-gray-300 rounded-md mt-2 p-2"
                onChange={(e) => setReplyText(e.target.value)}
                placeholder="Write your reply here..."
                value={replyText}
                required
              ></textarea>
              <button
                className="bg-gray-600 text-white rounded-md px-4 py-2 mt-4"
                onClick={handleReplyButtonSubmit}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UserMessages;
