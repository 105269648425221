import { db, auth } from "../firebase";
import { useState, useEffect } from "react";
import {
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  collection,
} from "firebase/firestore";
import { deleteUser, signOut, sendPasswordResetEmail } from "firebase/auth";

function Settings() {
  const user = auth.currentUser.email;
  const [docID, setDocID] = useState();
  const [username, setUsername] = useState();
  const [PasswordResetModal, setPasswordResetModal] = useState(false);
  const [AccountDeleteModal, setAccountDeleteModal] = useState(false);

  // UseEffect to fetch user document ID when the component is mounted
  useEffect(() => {
    // Create a firestore query to find ID based on the logged in user
    const ref = query(collection(db, "users"), where("email", "==", user));
    const Rinfo = async () => {
      // Fetch user document and set document ID
      (await getDocs(ref)).forEach((doc) => {
        setDocID(doc.id);
      });
    };
    Rinfo();
  }, [user]);

  // Function to OPEN password reset modal
  const ResetPasswordClick = () => {
    setPasswordResetModal(true);
  };
  // Function to CLOSE password reset modal
  const PasswordResetModalClose = () => {
    setPasswordResetModal(false);
  };

  // Function to OPEN account deletion modal
  const AccountDeleteClick = () => {
    setAccountDeleteModal(true);
  };
  // Function to CLOSE account deletion modal
  const AccountDeleteModalClose = () => {
    setAccountDeleteModal(false);
  };

  // Handle username change
  const handleUsernameChange = async () => {
    const uc = doc(db, "users", docID);
    await updateDoc(uc, {
      username: username,
    });
    alert("Username has been updated!");
    setUsername("");
  };

  // Handle password reset
  const handlePasswordReset = () => {
    sendPasswordResetEmail(auth, user)
      .then(() => {
        alert(
          "Password reset email has been sent, check your email and then login with the new password."
        );
        signOut(auth); // Sign out after sending password reset email
        window.location.reload(false);
      })
      .catch((error) => {
        alert("There was an error: " + error.message);
      });
  };

  // Handle account deletion
  const handleAccountDeletion = async () => {
    const cu = auth.currentUser;
    const userDocRef = doc(db, "users", docID);
    try {
      // Remove corresponding document from firestore and delete user account
      await deleteDoc(userDocRef);
      await deleteUser(cu);
      alert("Account has been deleted!");
      window.location.reload(false);
    } catch (error) {
      alert("There was an error: ", error.message);
    }
  };

  return (
    <div>
      <h1 className="text-3xl text-center font-bold mb-4">Settings</h1>
      <div className="w-1/2 mb-8">
        <label htmlFor="username" className="block mb-2 ml-2">
          Change Username
        </label>
        <div className="flex items-center ml-2">
          <input
            type="text"
            value={username}
            placeholder="New username here"
            className="border border-gray-300 px-4 py-2 mr-2 w-full"
            onChange={(e) => setUsername(e.target.value)}
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleUsernameChange}
          >
            Submit
          </button>
        </div>
      </div>
      <div>
        <button
          onClick={ResetPasswordClick}
          className="bg-green-500 text-white px-4 py-2 rounded mr-2 ml-2"
        >
          Reset Password
        </button>
        <button
          onClick={AccountDeleteClick}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Delete Account
        </button>
      </div>
      {PasswordResetModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="relative flex flex-col items-center bg-white p-8 rounded-lg">
            <p>Are you sure you want to reset password?</p>
            <div className="flex justify-center mt-4">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                onClick={handlePasswordReset}
              >
                Yes
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={PasswordResetModalClose}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {AccountDeleteModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="relative flex flex-col items-center bg-white p-8 rounded-lg">
            <p>
              Are you sure you want to{" "}
              <span className="text-red-500">delete your account?</span>
            </p>
            <div className="flex justify-center mt-4">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                onClick={handleAccountDeletion}
              >
                Yes
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={AccountDeleteModalClose}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Settings;
