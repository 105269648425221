import { db, auth } from "../firebase";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import "../components/styleResources/text-gradient.css";
import AdminDashboard from "../components/AdminDashboard";
import StudentDashboard from "../components/StudentDashboard";
import TeacherDashboard from "../components/TeacherDashboard";
import CreatorDashboard from "../components/CreatorDashboard";
import { UsernameProvider } from "../context/UsernameContext";
import { collection, query, where, getDocs } from "firebase/firestore";

function Home() {
  const [userState, setUserState] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  // useEffect to listen for authentication changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserState(!!user);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []); // Empty dependency array to make sure it only runs once

  // Fetches logged in user when component mounts
  useEffect(() => {
    const getUserAccountType = async () => {
      if (auth.currentUser) {
        const userEmail = auth.currentUser.email;
        const q = query(
          collection(db, "users"),
          where("email", "==", userEmail)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setUserInfo(userData);
        }
      }
    };

    if (userState) {
      getUserAccountType();
    }
  }, [userState]); // Dependency array to run when userState changes

  return (
    <>
      {userState && userInfo ? (
        userInfo.accountType === "Teacher" ? (
          <UsernameProvider>
            <TeacherDashboard /> {/* accountType: "Teacher" */}
          </UsernameProvider>
        ) : userInfo.accountType === "Student" ? (
          <UsernameProvider>
            <StudentDashboard /> {/* accountType: "Student" */}
          </UsernameProvider>
        ) : userInfo.accountType === "Creator" ? (
          <UsernameProvider>
            <CreatorDashboard /> {/* accountType: "Creator" */}
          </UsernameProvider>
        ) : userInfo.email === process.env.REACT_APP_SUPER_USER_EMAIL_ID ? (
          <UsernameProvider>
            <AdminDashboard /> {/* accountType: "Admin" */}
          </UsernameProvider>
        ) : null
      ) : (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-semibold text-center mb-8">
              Share your expertise with people all around the world
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-700 mb-4">
                  Helps you create enticing video courses and e-books for a wide
                  audience.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-700 mb-4">
                  Seamless processes that enable you to move along quickly and
                  effortlessly.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-700 mb-4">
                  A variety of pricing plans and different packages to help
                  everyone get their expertise shared online, no matter how big
                  or small.
                </p>
              </div>
            </div>
            <div className="text-center mt-8">
              <Link to="/register">
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-full">
                  Get Started!
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
